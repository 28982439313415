/**
 * Page with sidebar example
 */
import template from './setup.html';

class CRMSetupPageVM
{
	constructor (page) 
	{
		this.page = page;

		this.tabs = ko.observableArray([{
			route: '/ui/crm/setup/sellers',
			page_id: '[/ui/crm/setup/]sellers',
			title: 'Sellers',
			icon: 'fad fa-info-circle'
		},
		{
			route: '/ui/crm/contacts/search',
			page_id: '[/ui/crm/contacts/]search',
			title: 'Sale Types',
			icon: 'fad fa-info-circle'
		},
		{
			route: '/ui/crm/contacts/reports',
			page_id: '[/ui/crm/contacts/]reports',
			title: 'Custom Fields',
			icon: 'fad fa-info-circle'
		},
		{
			route: '/ui/crm/setup/products',
			page_id: '[/ui/crm/setup/]products',
			title: 'Products',
			icon: 'fad fa-info-circle'
 		},
		{
			route: '/ui/crm/contacts/types',
			page_id: '[/ui/crm/contacts/]types',
			title: 'Contacts Types',
			icon: 'fad fa-address-book'
		}]);

		this.current_page_id = ko.observable();
	}
	
	load_tab (page_id)
	{
		window.localStorage.setItem('crm_setup.last_sidebar_page', page_id);
		const element = this.page.element.querySelector('#page_content');
		this.current_page_id(page_id);
		const page = Grape.pages.find_page_by_id(page_id);
		if (!page)
			throw new Error(`Page with id "${page_id}" not found`);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class CRMSetupPage
{
	constructor (bindings, element, page) 
	{
		this.viewModel = new CRMSetupPageVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'CRMSetup';
		if (window.localStorage.getItem('crm_setup.last_sidebar_page'))
			this.viewModel.load_tab(window.localStorage.getItem('crm_setup.last_sidebar_page'));
		else
			this.viewModel.load_tab('[/sidebar-demo/]overview');
	}
}

export default {
	route: '[/]ui/crm/setup',
	page_class: CRMSetupPage,
	template: template
}
