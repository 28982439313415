import template from './search.html';

class ContactSearchVM
{
	constructor (page)
	{
		this.page = page;
	}
}

class ContactSearchPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new ContactSearchVM(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Contacts - Search';
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/ui/crm/contacts/]search',
	page_id: 'sidebar-subpage',
	page_class: ContactSearchPage,
	template: template
}
