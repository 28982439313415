import template from './seller-edit.html';
import Models from './Models.js';

/**
 * View Model
 */
class SellerEditViewModel {
	/**
	 * ViewModel constructor.
	 * @param {SellerEditClass} dialog - SellerEdit class
	 */
	constructor(dialog) {
		this.dialog = dialog;

		this.seller = ko.observable();
	}

	/**
	 * Handle click event for save button
	 */
	async save_click() {
		const result = await this.seller().save();
		console.log('reslt: ', result);
		await Grape.alerts.alert({title: 'Success', message: 'Seller saved'});
		this.dialog.close({});
	}

	/**
	 * Handle click event for close button
	 */
	close_click() {
		this.dialog.close(false);
	}
}

/**
 * SellerEdit
 */
class SellerEditClass {
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new SellerEditViewModel(this); // Name of the ViewModel defined above
	}

	get name() { return 'SellerEdit'; }

	async init() {
		if (this.bindings.seller)
		{
			const seller = new Models.Seller(this.bindings.seller);
			await seller.load();
			this.viewModel.seller(seller);
		}
		else
			this.viewModel.seller(new Models.Seller());
	}
}

export default {
	name: 'SellerEdit',
	dialog_class: SellerEditClass,
	template: template,
	provider: 'ps'
};
