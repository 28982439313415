class Seller
{
	constructor(obj)
	{
		this.seller_id = ko.observable()
		this.seller_uuid = ko.observable()
		this.name = ko.observable();
		this.website = ko.observable();
		this.contact_nr = ko.observable();
		this.email = ko.observable();
		this.address = ko.observable();
		this.company_reg = ko.observable();
		this.company_vat = ko.observable();
		this.banking_details = ko.observable();
		this.letterhead = ko.observable();
		this.default_invoice_template = ko.observable();
		this.default_invoice_message_uuid = ko.observable();

		if (obj)
			this.set(obj);
	}

	serialize()
	{
		return {
			seller_uuid: this.seller_uuid(),
			name: this.name(),
			fields: {
				address: this.address(),
				website: this.website(),
				contact_nr: this.contact_nr(),
				email: this.email(),
				company_reg: this.company_reg(),
				company_vat: this.company_vat(),
				banking_details: this.banking_details(),
				letterhead: this.letterhead(),
				default_invoice_template: this.default_invoice_template()
			}
		};
	}

	async load()
	{
		const result = await Grape.fetches.getJSON('/api/seller', { name: this.name() });
		await this.set(result.fields);
		return result;
	}

	async set(obj)
	{
		if (!obj)
			return;

		if (obj.hasOwnProperty('name'))
			this.name (obj.name);
		if (obj.hasOwnProperty('seller_uuid'))
			this.seller_uuid (obj.seller_uuid);
		if (obj.hasOwnProperty('fields')){
			if (obj.fields.hasOwnProperty('address'))
				this.address (obj.fields.address);
			if (obj.fields.hasOwnProperty('website'))
				this.website (obj.fields.website);
			if (obj.fields.hasOwnProperty('contact_nr'))
				this.contact_nr (obj.fields.contact_nr);
			if (obj.fields.hasOwnProperty('email'))
				this.email (obj.fields.email);
			if (obj.fields.hasOwnProperty('company_reg'))
				this.company_reg (obj.fields.company_reg);
			if (obj.fields.hasOwnProperty('company_vat'))
				this.company_vat (obj.fields.company_vat);
			if (obj.fields.hasOwnProperty('banking_details'))
				this.banking_details (obj.fields.banking_details);
			if (obj.fields.hasOwnProperty('letterhead'))
				this.letterhead (obj.fields.letterhead);
			if (obj.fields.hasOwnProperty('default_invoice_template'))
				this.default_invoice_template (obj.fields.default_invoice_template);
		}
	}

	async save()
	{
		const result = await Grape.fetches.postJSON('/api/sellers', this.serialize());

		return result;
	}
}

export default {
	Seller
};