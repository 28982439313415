import template from './sellers.html';

class ContactSellersVM
{
	constructor (page)
	{
		this.page = page;
		this.sellers = ko.observableArray();
	}

	async btnAdd_click()
	{
		await Grape.dialog.open('SellerEdit', {});

		await this.page.update();
	}

	async btnEdit_click(seller)
	{
		await Grape.dialog.open('SellerEdit', { seller: seller });

		await this.page.update();
	}
}

class ContactSellersPage
{
	constructor (bindings)
	{
		this.viewModel = new ContactSellersVM(this);
		this.bindings = bindings;
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Contacts - Sellers';
		this.viewModel.sellers(await Grape.cache.get('Sellers'));
	}

	async update ()
	{
		let sellers = await Grape.cache.refresh('Sellers');
		this.viewModel.sellers(sellers);
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/ui/crm/setup/]sellers',
	page_class: ContactSellersPage,
	template: template
}