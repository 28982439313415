/**
 * @kind cache
 * @class Sellers
 * @description Used to list all sellers
 * @usage Grape.cache.fetch('Sellers', function(data) { });
 */
export default {
	name: 'Sellers',
	options: {
		table: 'v_sales',
		schema: 'sales',
		sortfield: 'name'
	}
};
