//PAGES
import CRMSetupPage from './setup/setup.js';
import CRMSetupSellers from './setup/sellers/sellers.js';
import CRMSetupSellerType from './setup/sale-types/search.js';

export default [
	CRMSetupPage,
	CRMSetupSellers,
	CRMSetupSellerType
];
